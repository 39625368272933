<template>
    <div style="padding-bottom: 50px;">
        <div class="qianliang-header">
            <div class="qianliang-card-header" style="padding-left: 19px;">
                <img src="../../assets/img/qianliang/team/team.png" class="qianliang-card-icon">
                <div>团队数据</div>
            </div>
            <div class="team-header">
                <div>
                    <div>今日推荐</div>
                    <div>{{teamMsg.todayReference}}</div>
                </div>
                <div>
                    <div>累计推荐</div>
                    <div>{{teamMsg.totalReference}}</div>
                </div>
                <div>
                    <div>有效推荐</div>
                    <div>{{teamMsg.teamNum}}</div>
                </div>
                <div>
                    <div>总经验值</div>
                    <div>{{teamMsg.totalExperience}}</div>
                </div>
            </div>
        </div>
        <div class="qianliang-card-header" style="padding-left: 28px;">
            <img src="../../assets/img/qianliang/team/teamer.png" class="qianliang-card-icon">
            <div class="qianliang-card-title">
                团队成员
<!--                <span style="color: #aaa">（{{totalCount}}）</span>-->
            </div>
        </div>

        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadTeamer" >
            <van-cell-group inset class="qianliang-card" v-for="(item,index) in teamer" :key="index">
                <van-cell value-class="qianliang-phone">
                    <template #icon>
                        <img :src="item.headImg?item.headImg:defaultHeadUrl" style="margin-right: 6px;height: 64px;width: 64px;border-radius: 50%;">
                    </template>
                    <template #title>
                        <div style="display: flex;align-items: center;">
                            <div>{{item.nickName}}</div>
                            <!--                        <img src="../../assets/img/qianliang/team/copy.png" style="margin-left: 5px;height: 16px;width: 16px;">-->
                        </div>
                    </template>
                    <template #label>
                        <div>经验值：{{item.incomeExperience}}</div>
                        <div>加入时间：{{item.createTime}}</div>
                    </template>
                    <!--                <div style="width: 28px;display: flex;align-items: center;float: right;height: 100%;">-->
                    <!--          <img src="../../assets/img/qianliang/team/phone.png" style="width: 28px;height: 28px;">-->
                    <!--                </div>-->
                </van-cell>
                <van-cell style="padding: 5px 16px;" v-if="item.clickFlag === '0'" @click="showTeamer(item.fkMemberId)">
                    <template #title>
                        <div>状态：<span style="color: red;">未完成</span></div>
                    </template>
                    <van-icon name="arrow" />
                </van-cell>
            </van-cell-group>
        </van-list>
        <van-dialog v-model="show" :showConfirmButton="false" :show-cancel-button="true" cancel-button-text="关闭" cancelButtonColor="#888888" closeOnClickOverlay title="未完成签到清单">
            <div class="qianliang-card-body" style="padding-left: 20px;max-height: 300px;overflow: auto;">
                <div class="qianliang-card-qiandao" v-for="(item,index) in activity" :key="index" v-if="item.activityStatus === '1' && item.showStatus === '1'">
                    <div>
                        <div>{{item.title}}</div>
                        <div>
                            <div>奖励：<span class="text-color">{{item.liangpiaoNum}}</span> 黑钻</div>
                            <div>{{item.endTime.substr(0,10)}}</div>
                        </div>
                    </div>
                    <div style="font-size: 16px;color: #FEC919;padding: 0 15px;line-height: 45px;height: 45px;border-left: 2px solid #eee">{{(item.clickSpotNum?item.clickSpotNum:0)}}&nbsp;/&nbsp;{{item.spotNum}}</div>
                </div>
            </div>
        </van-dialog>
        <tabbar active="team"></tabbar>
    </div>
</template>

<script>
import Tabbar from "./tabbar";

export default {
    name: "team",
    data(){
        return {
            defaultHeadUrl:require('../../assets/img/qianliang/team/header.png'),
            show: false,
            teamMsg:{
                teamNum: 0,  // 有效推荐-团队成员数量
                todayReference: 0,  // 今日推荐
                totalExperience: 0,  // 总经验值
                totalReference: 0,  // 累计推荐
            },
            loading:false,
            finished:false,
            current: 0,  // 当前页
            totalPage: 0,  // 总页数
            totalCount: 0,  // 总条数

            teamer:[],
            activity:[],
        }
    },
    components: {Tabbar},
    created() {
        if(!localStorage.getItem('xToken') || !localStorage.getItem('userInfo')){
            this.$router.replace('/verify')
        }
    },
    mounted() {
        this.loadHeader();
        // this.loadTeamer();
    },
    methods:{
        loadHeader(){
            this.post('/member/team/queryStatistics',{
                id: JSON.parse(localStorage.getItem('userInfo')).id
            }).then(result => {
                console.log(result)
                if(result.respCode === "0"){
                    let data = result.data;
                    this.teamMsg.teamNum = data.teamNum ?? 0;
                    this.teamMsg.todayReference = data.todayReference ?? 0;
                    this.teamMsg.totalExperience = data.totalExperience ?? 0;
                    this.teamMsg.totalReference = data.totalReference ?? 0;
                } else {
                    // 提示阅读失败
                    that.$dialog.alert({
                        title: '查询失败',
                        message:result.respMsg,
                    })
                }
            })
        },
        loadTeamer(){
            let that = this
            this.current++;
            if(!JSON.parse(localStorage.getItem('userInfo')).wcId){
                that.totalPage = 0
                that.totalCount = 0
                that.loading = false;
                that.finished= true;
                return
            }
            this.post('/member/team/queryPageList',{
                current: this.current,
                size: 20,
                wcId: JSON.parse(localStorage.getItem('userInfo')).wcId,
            }).then(result => {
                if(result.respCode === "0"){
                    for(let i = 0; i < result.data.result.length; i++){
                        that.teamer.push(result.data.result[i])
                    }
                    that.totalPage = result.data.totalPage
                    that.totalCount = result.data.totalCount

                    that.loading = false;
                    console.log(this.current)
                    console.log(this.totalPage)
                    if(this.current >= this.totalPage){
                        this.finished= true;
                    }
                } else {
                    // 提示阅读失败
                    that.$dialog.alert({
                        title: '查询失败',
                        message:result.respMsg,
                    })
                }
            })
        },
        showTeamer(fkMemberId){
            let that = this
            this.post('/activity/queryTeamClickRunList',{teamId:fkMemberId}).then(result => {
                if (result.respCode === "0") {
                    that.activity = result.data.result
                }
            })
            this.show = true
        },

    }
}
</script>

<style scoped>
.team-header{
    background: #fff;
    margin: 0 24px;
    padding:11px 0;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
}
.team-header>div{
    flex: 1 1 25%;
}
.team-header>div>div:first-child{
    font-size: 12px;
    color: #848484;
}
.team-header>div>div:nth-child(2){
    font-size: 18px;
    color: #101010;
}
.qianliang-phone{
    flex: 0 0 auto;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.block {
    width: 80%;
    max-height: 50%;
    background-color: #fff;
    overflow: auto;
}
</style>
